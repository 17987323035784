<template>
    <div id="ad">
        <div class="banner">
            <img src="@/assets/img/ad_banner.png" alt="">
            <div class="text">
                <p>做电商 找合作</p>
                <p>专业优质的电商服务平台</p>
                <p>
                    <span>√OEM产品合作</span>
                    <span>√广告位合作</span>
                    <span>√源码部署</span>
                </p>
            </div>
        </div>
        <div class="section1">
            <div class="title">天牛电商</div>
            <p class="detail"><span>天牛电商</span>是一家以服务为主的电子商务平台。<br>
平台致力于为企业提供优质电商发展解决方案和运营服务，依托深厚的研发和运营技术，<br>
平台积累了丰富的店铺运营、社会化营销和互联网商业服务经验，得到业界的一致认可。</p>
            <p class="t1">皆可源码部署、广告合作，详情请扫描下方二维码咨询招商经理</p>
            <img src="@/assets/img/qrcode.png" alt="">
            <p>添加招商经理微信</p>
        </div>
        <!-- <div class="section2">
            <div class="item" @click="toUrl('https://www.shailema.com/')">
                <img src="@/assets/img/ad_logo1.png" alt="">
                <p class="name">晒了吗买家秀平台</p>
                <div class="des">为中小型电商店铺提供买家秀服务<br>买家秀为商家加速突破权重困局</div>
                <div>
                    查看更多<i class="el-icon-arrow-right"></i>
                </div>
            </div>
            <div class="item" @click="toUrl('https://www.ganjinsheng.com/')">
                <img src="@/assets/img/ad_logo2.png" alt="">
                <p class="name">赶紧省淘宝客平台</p>
                <div class="des">导购加返利型创业平台，每一次下单<br>都能省，优惠券返利领到手软，分享<br>导购还能赚外快</div>
                <div>
                    查看更多<i class="el-icon-arrow-right"></i>
                </div>
            </div>
            <div class="item" @click="toUrl('https://www.sxlon.com/')">
                <img src="@/assets/img/ad_logo3.png" alt="">
                <p class="name">真香试试用平台</p>
                <div class="des">打造精确人群标签，提升关键词排名<br>安全不降权，丰富的用户资源</div>
                <div>
                    查看更多<i class="el-icon-arrow-right"></i>
                </div>
            </div>
            <div class="item" @click="toUrl('http://www.ganjinla.com/')">
                <img src="@/assets/img/ad_logo4.png" alt="">
                <p class="name">来人快收藏加购平台</p>
                <div class="des">匹配精准人群标签，精准把控加购率<br>收藏率，全面提升店铺权重，提升销<br>量</div>
                <div>
                    查看更多<i class="el-icon-arrow-right"></i>
                </div>
            </div>
            <div class="item"  @click="toUrl('https://www.fabwang.com/')">
                <img src="@/assets/img/ad_logo5.png" alt="">
                <p class="name">FAB网礼品网</p>
                <div class="des">电商商家赠品采购与发货一站式服务<br>可以多地区发货，成本低廉</div>
                <div>
                    查看更多<i class="el-icon-arrow-right"></i>
                </div>
            </div>
            <div class="item" @click="toUrl('https://www.guotuitui.com/')">
                <img src="@/assets/img/ad_logo6.png" alt="">
                <p class="name">裹推推</p>
                <div class="des">采用新的快递广告投放模式，联合电<br>商商家入驻平台，细分商家快递类目</div>
                <div>
                    查看更多<i class="el-icon-arrow-right"></i>
                </div>
            </div>
            <div class="item" @click="toUrl('https://www.zbxpw.com/')">
                <img src="@/assets/img/ad_logo7.png" alt="">
                <p class="name">直播选品网</p>
                <div class="des">为直播机构及主播<br>提供一站式选品服务</div>
                <div>
                    查看更多<i class="el-icon-arrow-right"></i>
                </div>
            </div>
            <div class="item" @click="toUrl('https://www.yunheihao.com/')">
                <img src="@/assets/img/ad_logo8.png" alt="">
                <p class="name">云黑号验号平台</p>
                <div class="des">免费提供淘买家信誉查询<br>有效预防恶评差评</div>
                <div>
                    查看更多<i class="el-icon-arrow-right"></i>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            
        }
    },
    created(){},
    mounted(){},
    watch: {},
    methods: {
        toUrl(url){
            window.open(url);
        }
    }
}
</script>

<style lang="scss" scoped>
#ad{
    .banner{
        position: relative;
        height: 400px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        }
        .text{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            color: #fff;
            text-align: center;
            p{
                &:first-child{
                    font-size: 90px;
                    font-weight: bold;
                }
                &:nth-child(2){
                    font-size: 36px;
                    font-weight: bold;
                }
                &:last-child{
                    margin-top: 15px;
                    font-size: 16px;
                    font-weight: bold;
                    span{
                        &:not(:first-child){
                            margin-left: 40px;
                        }
                    }
                }
            }
        }
    }
    .section1{
        text-align: center;
        .title{
            font-size: 36px;
            font-weight: bold;
            color: #333;
            margin: 40px auto;
        }
        .t1{
            font-size: 14px;
            color: #FF8800;
            margin: 40px auto;
        }
        .detail{
            font-size: 16px;
            color: #999;
            span{
                font-weight: bold;
                color: #FF8800;
            }
        }
        img{
            width: 200px;
            height: 200px;
            display: block;
            margin: auto;
            & + p{
                font-size: 22px;
                font-weight: bold;
                color: #666666;
                margin-top: 20px;
            }
        }
    }
    .section2{
        width: 1240px;
        margin: 25px auto 0;
        .item{
            display: inline-block;
            vertical-align: bottom;
            text-align: center;
            width: 270px;
            height: 290px;
            cursor: pointer;
            padding: 30px 20px 20px 20px;
            box-sizing: border-box;
            transition: box-shadow 0.3s;
            &:hover{
                box-shadow: 0px 2px 25px 0px rgba(0,0,0,0.20); 
            }
            img{
                width: 80px;
                height: 80px;
                margin: auto;
                display: block;
            }
            .name{
                font-size: 18px;
                color: #333333;
                font-weight: bold;
                margin: 13px auto;
            }
            .des{
                font-size: 14px;
                color: #666666;
                & + div{
                    margin-top: 13px;
                    color: #FF8800;
                    font-size: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    i{
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
</style>